import React, {useEffect} from "react"
import tw from 'twin.macro'
import Layout from '../../components/Layout'
import {Box, Text} from '../../components/style'
import useScript from '../../hooks/useScript'
import {verifyEmail} from '../../api'


export default function Token({location, token}) {
  // console.log({location, token})
  useScript('https://platform.twitter.com/widgets.js')

  useEffect( () => {
      // console.log("useEffect triggered")
      verifyEmail({token});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  );
  

  return (
    <Layout style={{ paddingTop: 150 }}>
      <Box>
        <Text>تم ارسال الرسالة الى <span tw="font-bold">المستقبل</span>!</Text>
        <Text>ألهم متابعينك وأصحابك لتحقيق أهدافهم بتغريدة ❤</Text>
        <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className="twitter-share-button" data-size="large" data-text="ارسل رسالة الى نفسك بالمستقبل واكتب فيها أهدافك وطموحاتك اللي بتحققها، وذوق نفسك المستقبلية طعم الانجاز! " data-url="https://mustqbl.me" data-via="MustqblMe" data-hashtags="انت_قدها" data-related="MustqblMe" data-lang="ar" data-show-count="false">Tweet</a>
      </Box>
    </Layout>
  )
}
